import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'
import { getImage, getSrc } from "gatsby-plugin-image"
import { withPrismicPreview } from 'gatsby-plugin-prismic-previews'

import LayoutNew from "../components/layout-new"
import HeroSection from "../components/common/hero-section"
import Seo from "../components/seo"

const divContainer = "pt-16 pb-12"
const divContainerGreen = "bg-site-green-light pt-16 pb-12"
const divWrapper = "relative w-11/12 lg:w-10/12 max-w-screen-lg mx-auto"
const numberWrapper = "relative lg:absolute top-0 mb-6 lg:mb-0"
const numberStyle = "w-24 h-24 flex items-center justify-center text-6xl text-white bg-site-red rounded-full font-black"
const textWrapper = "w-full max-w-xl mx-auto"
const h3Style = "text-site-red text-xl md:text-2xl font-bold mb-3"
const pStyle2 = "text-base md:text-lg mb-6"

const PageTenThings = () => {
  const data = useStaticQuery(graphql`
    query PageTenThingsQuery {
      prismicTenThingsPage {
        _previewable
        data {
          page_title
          page_short_description
          page_hero_background {
            gatsbyImageData(
              width: 1920
            )
          }
          number_one_title
          number_one_text
          number_two_title
          number_two_text
          number_three_title
          number_three_text
          number_four_title
          number_four_text
          number_five_title
          number_five_text
          number_six_title
          number_six_text
          number_seven_title
          number_seven_text
          number_eight_title
          number_eight_text
          number_nine_title
          number_nine_text
          number_ten_title
          number_ten_text
        }
      }
      imgHero: file(relativePath: { eq: "backgrounds/ten-things-bg.jpg" }) {
        childImageSharp {
          gatsbyImageData(
            width: 1920
          )
        }
      }
    }
  `)

  const doc = data.prismicTenThingsPage
  const imgHero = getImage(doc.data.page_hero_background)
  const imgSrc = getSrc(doc.data.page_hero_background)

  return (
    <LayoutNew>
      <Seo
        title={doc.data.page_title}
        description={doc.data.page_short_description}
        image={imgSrc}
      />
      <HeroSection
        title={doc.data.page_title}
        imgSrc={imgHero}
      />

      <div className={divContainer}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>1</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_one_title}</h3>
            <p className={pStyle2}>{doc.data.number_one_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainerGreen}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>2</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_two_title}</h3>
            <p className={pStyle2}>{doc.data.number_two_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainer}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>3</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_three_title}</h3>
            <p className={pStyle2}>{doc.data.number_three_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainerGreen}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>4</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_four_title}</h3>
            <p className={pStyle2}>{doc.data.number_four_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainer}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>5</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_five_title}</h3>
            <p className={pStyle2}>{doc.data.number_five_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainerGreen}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>6</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_six_title}</h3>
            <p className={pStyle2}>{doc.data.number_six_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainer}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>7</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_seven_title}</h3>
            <p className={pStyle2}>{doc.data.number_seven_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainerGreen}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>8</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_eight_title}</h3>
            <p className={pStyle2}>{doc.data.number_eight_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainer}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>9</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_nine_title}</h3>
            <p className={pStyle2}>{doc.data.number_nine_text}</p>
          </div>
        </div>
      </div>

      <div className={divContainerGreen}>
        <div className={divWrapper}>
          <div className={numberWrapper}>
            <span className={numberStyle}>10</span>
          </div>
          <div className={textWrapper}>
            <h3 className={h3Style}>{doc.data.number_ten_title}</h3>
            <p className={pStyle2}>{doc.data.number_ten_text}</p>
          </div>
        </div>
      </div>

      <div className="bg-gray-100 flex flex-col justify-center items-center pt-0 pb-12 md:pb-16">
        <p className="text-site-red text-2xl font-semibold text-center tracking-wider mb-6">Do You Qualify?</p>
        <Link to="/qualify">
          <button className="w-full mb-8 lg:mb-0 md:w-auto bg-site-red hover:bg-site-red-hover text-base md:text-lg text-white uppercase tracking-wider px-8 py-3">
            Begin Assessment
          </button>
        </Link>
      </div>
    </LayoutNew>
  )
}

export default withPrismicPreview(PageTenThings)
